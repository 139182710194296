.navbarComp{
    .navbar {
        background-color: #b6a277 !important;

        .cartbutton {
            width: 55px;
            height: 35px;
            border: unset !important;
            color: #ffffff !important;

            i {
                font-size: 25px;
            }
        }

        .badgeCart{
            position: relative;
            top: -40px;
            left: 20px;
            background: #c42525;
            border-radius: 100%;
            font-family: sans-serif;
        }
    }
    .footer {
        position: absolute;
        bottom: 0px;
        width: 90%;
    }
}
.home {
    .swiper{
        overflow: unset;
    }
    .swiper-container {
        width: 100%;
        overflow: hidden;
        min-height: 175px;
    }

    .swiper-slide {
        text-align: center;
        transition:
            transform 0.3s,
            opacity 0.3s;
        display: flex !important;
        justify-content: center;
    }

    .swiper-slide .slide-content img {
        width: 75%;
        transition:
            transform 0.3s,
            opacity 0.3s;
    }

    .swiper-slide .slide-content .label {
        font-size: 10px;
        font-family: "Gotham";
        color: #582f1e;
        opacity: 0.5;
    }

    .swiper-slide-active .slide-content .label {
        font-size: 15px;
        opacity: 1;
        font-family: "Gotham";
        color: #582f1e;
    }

    /* Slide non attive - ridotte e con posizione più alta */
    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0.5;
    }

    /* Slide centrali */
    .swiper-slide .slide-content {
        width: 60%;
        transition:
            transform 0.3s,
            opacity 0.3s;
        border-radius: 20px;
        transform: translateY(5px);
        /* Posizione più alta per le slide laterali */
    }

    .swiper-slide-active .slide-content {
        width: 100%;
        transform: scale(1.1) translateY(25px);
        /* Slide centrale più grande e più in basso */
        opacity: 1;
        border-radius: 30px;
    }

    /* Slide laterali più vicine al centro - un po' più in basso */
    .swiper-slide-next .slide-content,
    .swiper-slide-prev .slide-content {
        transform: translateY(25px);
        /* Posizione più bassa per le slide laterali più vicine */
    }



    .intestazione {
        font-family: "BillyBond";
        font-size: 50px;
        color: rgb(138 112 64) !important;
        height: 55px;
    }

    .subIntestazione {
        font-family: "Gotham";
        font-size: 27px;
        color: #605130;
    }

    #product-detail {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            max-width: 500px;
            width: 100%;
        }

        .label {
            font-size: 35px;
            font-family: "Gotham";
            color: #582f1e;
            text-transform: uppercase;
            margin-bottom: 0px;
        }

        .description {
            font-size: 16px;
            color: #582f1e;
        }

        .price {
            font-size: 35px;
            opacity: 1;
            font-family: "Gotham";
            color: #c42525;

            .iva-label {
                font-size: 12px;
                color: #8c8c8c;
            }
        }

        .btn-carrello {
            background-color: #632b17;
            color: #ffffff;
            border-radius: unset;
            font-size: 12px;
        }
        .arrowmove {
            font-size: 20px;
            color: #b6a277;
        }
    }



    @media screen and (max-width: 767px) {
        .swiper-container {
            min-height: 120px;
        }
    }

    @media screen and (max-width: 430px) {
        .swiper-slide .slide-content {
            width: 100%;
        }

        .swiper-slide-active .slide-content {
            transform: scale(1.3) translateY(35px);
        }
    }
}
